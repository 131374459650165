import React from 'react'
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow } from "../helpers/transitionHelper"
import ContentBuilder from '../components/ContentBuilder'


export const query = graphql`
  query PageTemplateTemplateQuery($id: String!) {
    post: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      contentBuilder {
        ... on SanityHeroImage {
          _key
          _type
          _rawImage
        }
        ... on SanityHeroText {
          _key
          _type
          title
          _rawText
        }
        ... on SanityLongText {
          _key
          _type
          _rawBody
          heading
          link
          linkText
          showLink
        }
        ... on SanityPeopleBlock {
          _key
          _type
          heading
          people {
            _id
            name
            slug {
              current
            }
            shortCv
            links {
              link
              title
            }
            _rawPortrait
          }
        }
      }
    }
  }
`

const PageTemplate = props => {
  const { data } = props
  const post = data && data.post
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <motion.div variants={fadeSlow}>
         <motion.div className="content-builder" variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: props.childAnimationDelay
          }}
        }}>
          {/* <motion.h1 variants={revealInOut} className="page-title post-title">{post.title}</motion.h1> */}
          <ContentBuilder modules={post.contentBuilder}/>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default PageTemplate